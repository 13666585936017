import ChangClause from '@/page/distributor/air-ticket/components/changClause.vue';
import LuggageClause from '@/page/distributor/air-ticket/components/luggageClause/index.vue';
import airStop from "@/page/distributor/air-ticket/components/airStop.vue";
import NationalitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/nationality-selector/1.0.0/index.vue';
import buyer_dom_white_pnrImport from '@/lib/data-service/flight/buyer_dom_white_pnrImport';
import buyer_dom_white_rule from '@/lib/data-service/flight/newFligthApi/buyer_dom_white_rule';
import buyer_dom_white_pnrOrder from '@/lib/data-service/flight/newFligthApi/buyer_dom_white_pnrOrder';
import buyer_dom_white_pnrAmountDetail from '@/lib/data-service/flight/newFligthApi/buyer_dom_white_pnrAmountDetail';
import buyer_dom_white_luggage from '@/lib/data-service/flight/buyer_dom_white_luggage';
import moment from "moment";
import buyer_dom_white_ticketVoucherTypeEnumList
    from "@/lib/data-service/flight/buyer_dom_white_ticketVoucherTypeEnumList";
import buyer_dom_white_policyProductTypeEnumList
    from "@/lib/data-service/flight/buyer_dom_white_policyProductTypeEnumList";

import _ from 'underscore';

export default {
    data() {
        return {
            arrowsShow: false,

            loading: false,
            containerLoading: false,
            fixedSubmit: false,
            flightInfo: {},
            oriPolicyList: [], // 原始政策列表
            policysList: [],
            dfdList: [],
            rawData: [],
            uuid:'',
            form: {
                passengerList: [],
                policys: 0,
                contactEmail: '',
                contactName: '',
                contactPhone: '',
                remark: '',
                sequenceKey:'',// 提交订单的参数
            },
            psgType: 1,
            rules: {
                contactName: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                ],
                contactPhone: [
                    { required: true, validator: this.validatePhone, trigger: 'blur' },
                ],
            },
            cardTypeList: [
                { value: 1, name: '身份证' },
                { value: 2, name: '护照' },
                // {value: 2, name: ''},
                // {value: 3, name: '身份证'},
                { value: 4, name: '港澳通行证' },
                { value: 5, name: '台胞证' },
                { value: 6, name: '台湾通行证' },
                { value: 7, name: '回乡证' },
                { value: 8, name: '军官证' },
                { value: 9, name: '其他' }
            ],
            ruleText: [],
            luggage: '',
            checked: false,
            luggageLoading: false,

            errorText: '',
            errorCode: '',
            dialogError2Visible: false,

            checkedProductTypeAll: false,
            productTypeList: [],
            productTypeEnumList: [], // 枚举列表
            checkPolicyPriceAll: false,
            policyPriceList: [],
            policyPriceEnumList: [], // 枚举列表
            checkTicketVoucherType: false,
            ticketVoucherTypeList: [],
            ticketVoucherTypeEnumList: [], // 枚举列表

            lpLowestPrice: null, // 最低价
            ypLowestPrice: null, // 平台最低价
            gwLowestPrice: null, // 官网最低价

            radio: '',

            policyMaxCount: 5,

            pnrAmountDetail: {},

            sexType: [
                {value: 1, name: "男"},
                {value: 2, name: "女"},
            ],
            adultCount:'',
            childCount:''
        };
    },
    mounted() {
      this.lpLowestPrice = null;
      this.ypLowestPrice = null;
      this.gwLowestPrice = null;
    },
    filters: {
        trainStartFilter(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
        getWeekFilter(date) { // 参数时间戳
            let week = moment(date).day()
            switch (week) {
                case 1:
                    return '周一'
                case 2:
                    return '周二'
                case 3:
                    return '周三'
                case 4:
                    return '周四'
                case 5:
                    return '周五'
                case 6:
                    return '周六'
                case 0:
                    return '周日'
            }
        },
    },
    methods: {
        getTicketVoucherTypeEnumList() {
            buyer_dom_white_policyProductTypeEnumList().then(res => {
                this.productTypeEnumList = res.res
            })
        },
        getPolicyProductTypeEnumList() {
            buyer_dom_white_ticketVoucherTypeEnumList().then(res => {
                this.ticketVoucherTypeEnumList = res.res
            })
        },
        changeProductTypeAll(val) {
            if (val) {
                this.productTypeList = []
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changeProductTypeList(val) {
            if (val.length > 0) {
                this.checkedProductTypeAll = false
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changePolicyPriceAll(val) {
            if (val) {
                this.policyPriceList = []
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changePolicyPriceList(val) {
            if (val.length > 0) {
                this.checkPolicyPriceAll = false
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changeTicketVoucherAll(val) {
            if (val) {
                this.ticketVoucherTypeList = []
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changeTicketVoucherList(val) {
            if (val.length > 0) {
                this.checkTicketVoucherType = false
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        // 过滤政策
        filterPolicy() {
            let oriPolicyList = JSON.parse(JSON.stringify(this.oriPolicyList));

            // 过滤政策类型
            if (this.productTypeList.length > 0) {
                let policyList = []
                // 1:平台优选 2:低价推荐 3:官网
                let productTypeList = []
                if (this.productTypeList.indexOf(1) > -1) {
                    productTypeList.push('平台优选')
                }
                if (this.productTypeList.indexOf(2) > -1) {
                    productTypeList.push('低价推荐')
                }
                if (this.productTypeList.indexOf(3) > -1) {
                    productTypeList.push('官网')
                }
                oriPolicyList.forEach(value => {
                    if (productTypeList.indexOf(value.productTypeTxt) > -1) {
                        policyList.push(value)
                    }
                })
                oriPolicyList = policyList
            }
            // 过滤价格
            if (this.policyPriceList.length > 0) {
                let policyList = []
                this.oriPolicyList.forEach(value => {
                    if (this.policyPriceList.indexOf(value.priceData[0].price) > -1) {
                        policyList.push(value)
                    }
                })
                oriPolicyList = policyList
            }
            // 过滤票证类型
            if (this.ticketVoucherTypeList.length > 0) {
                let policyList = []
                this.oriPolicyList.forEach(value => {
                    if (this.ticketVoucherTypeList.indexOf(value.ticketVoucherType) > -1) {
                        policyList.push(value)
                    }
                })
                oriPolicyList = policyList
            }
            this.policysList = oriPolicyList
        },
        toChangePolicy(val, index) {
            this.form.policys = index
        },



        goRule() {
            const url = 'https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2021/06/16/%E3%80%8A%E8%83%A4%E4%B9%8B%E6%97%85%E6%93%8D%E4%BD%9C%E8%A7%84%E8%8C%83-%E6%9C%BA%E7%A5%A8%E7%BC%96%20V2.0%E7%89%88%E3%80%8B.pdf'
            window.open(url)
        },
        goRead(item) {
            window.open(item.fileUrl)
        },
        init() {
            this.loading = true;
            const uuid = this.$route.query.id;
            this.uuid = uuid;
            this.checkedProductTypeAll = false;
            this.productTypeList = [];
            this.productTypeEnumList = []; // 枚举列表
            this.checkPolicyPriceAll = false;
            this.policyPriceList = [];
            this.policyPriceEnumList = []; // 枚举列表
            this.checkTicketVoucherType = false;
            this.ticketVoucherTypeList = [];
            this.ticketVoucherTypeEnumList = []; // 枚举列表

            this.getTicketVoucherTypeEnumList();
            this.getPolicyProductTypeEnumList();

            buyer_dom_white_pnrImport({ uuid, enable_error_alert: false})
                .then(data => {
                    let flightInfo = {};
                    const dfd = data.dfdList[0];
                    this.dfdList = dfd;
                    const policysList = dfd.policys.map(item => {
                        return Object.assign({}, item, {
                            isSaveFrequent: 0,
                            airCompanyCode: dfd.airCompanyCode
                        });
                    });
                    this.form.contactPhone = data.ctctMobile;
                    this.form.contactName = data.ctctName;
                    this.rawData = dfd.rawData;

                    this.lpLowestPrice = dfd.lpLowestPrice;
                    this.ypLowestPrice = dfd.ypLowestPrice;
                    this.gwLowestPrice = dfd.gwLowestPrice;
                    this.adultCount = data.adultCount;
                    this.childCount= data.childrenCount;

                    flightInfo.airCompanyLogoImg = dfd.airCompanyLogoImg;
                    flightInfo.airCompanyName = dfd.airCompanyName;
                    flightInfo.flightNo = dfd.flightNo;
                    flightInfo.depAirportName = dfd.depAirportName;
                    flightInfo.depCityName = dfd.depCityName;
                    flightInfo.depDate = dfd.depDate;
                    flightInfo.depTime = dfd.depTime;
                    flightInfo.durationStr = `${dfd.stopNum ? '经停' : '直达'} ${dfd.duration.replace(/h/g, '小时').replace(/m/g, '分')}`;
                    flightInfo.arrAirportName = dfd.arrAirportName;
                    flightInfo.arrCityName = dfd.arrCityName;
                    flightInfo.arrDate = dfd.arrDate;
                    flightInfo.arrTime = dfd.arrTime;
                    flightInfo.depCityCode = dfd.depCityCode;
                    flightInfo.arrCityCode = dfd.arrCityCode;
                    flightInfo.arrAirportCode = dfd.arrAirportCode;
                    flightInfo.depAirportCode = dfd.depAirportCode;
                    flightInfo.adultCount = data.adultCount;
                    flightInfo.childrenCount = data.childrenCount;
                    flightInfo.importPnr = data.importPnr;
                    flightInfo.cabinName = dfd.cabinName;

                    flightInfo.cabinCode = policysList[0].cabin.cabinCode;
                    flightInfo.ticketCount = policysList[0].cabin.ticketCount;

                    this.flightInfo = flightInfo;
                    this.oriPolicyList = JSON.parse(JSON.stringify(policysList))
                    this.filterPolicy();

                    if(null != dfd.policyFacePriceList
                        && undefined != dfd.policyFacePriceList
                        && dfd.policyFacePriceList.length>0){
                        let policyFacePriceList=dfd.policyFacePriceList;
                        policyFacePriceList = _.uniq(policyFacePriceList);
                        policyFacePriceList.forEach(item => {
                            this.policyPriceEnumList.push({
                                value:item
                            })
                        })
                    }

                    this.form.passengerList = data.passengerList.map(item => {
                        let birthday = '';
                        let sex = ''
                        item.isSelectBirthday=false;
                        if (item.cardType === 1) {
                            birthday = `${item.cardNo.substr(6, 4)}-${item.cardNo.substr(10, 2)}-${item.cardNo.substr(12, 2)}`;
                            sex = parseInt(item.cardNo.substr(16, 1), 10) % 2 === 1 ? 1 : 2;
                            item.isSelectBirthday = true;
                        }

                        if(item.passengerAdultList == null && item.psgType==2){
                            let adu = [];
                            adu = [{
                                   adultTicketNumber:'',
                                   adultPnr:'',
                                   adultCabin:''
                               }];
                            item.passengerAdultList=adu;
                        }
                        item
                        return Object.assign({}, item, {
                            birthday,
                            sex,
                            countryCode: 'CN',
                            countryCodeObj: {
                                code: 'CN'
                            },
                            issCountryCode: 'CN',
                            issCountryCodeObj: {
                                code: 'CN'
                            },
                            cardExpireDate: ''
                        });
                    });
                    debugger
                    if (this.flightInfo.adultCount && this.flightInfo.childrenCount) {
                        this.psgType = 3;
                    } else if (this.flightInfo.adultCount) {
                        this.psgType = 1;
                    } else {
                        this.psgType = 2;
                    }
                    return data;
                }, error => {
                    if (error.data.code === '000802') {
                        this.errorText = error.data.msg
                        this.errorCode = error.data.code
                        this.dialogError2Visible = true
                    }
                })
                .then(() => {
                    this.getPnrAmountDetail()
                        .then((pnrData) => {
                            this.form.sequenceKey=pnrData.sequenceKey;
                            this.pnrAmountDetail = pnrData;
                            this.pnrAmountDetail.orderResponse.result.preferentialExplain = JSON.parse(this.pnrAmountDetail.orderResponse.result.preferentialExplain);

                        }).finally(() => {
                            this.loading = false;
                            this.$nextTick(() => {
                                document.addEventListener('scroll', this.scrollHandler);
                                this.scrollHandler();
                            });
                        });
                })
                .catch(() => {
                    this.$router.push('/air-ticket');
                })
        },
        closeErrorDialog() {
            this.dialogError2Visible = false
            if (this.errorCode === '000802') {
                this.$router.replace({
                    name: 'air-ticket',
                    quyer: {
                        tab: 3
                    }
                })
            }
        },
        getPnrAmountDetail() {
            let segments = [{
                supplierType: Number(this.policysList[this.form.policys].supplierType),
                segmentType: 1,
                flightNo: this.dfdList.flightNo,
                cabinCode: this.policysList[this.form.policys].cabin.cabinCode,
                policyId: this.policysList[this.form.policys].policyId,
                depCityPortCode: this.dfdList.depAirportCode,
                arrCityPortCode: this.dfdList.arrAirportCode,
                depDate: this.dfdList.depDate,
                businessExt: this.policysList[this.form.policys].businessExt
            }];
            let body = {
                flightType: 1,
                cabinRank: this.policysList[this.form.policys].cabin.cabinRank,
                directFlight: 0, // 测试值
                psgType: this.psgType,
                segments,
                adultCount: this.flightInfo.adultCount,
                childrenCount: this.flightInfo.childrenCount,
                price: this.priceDataItem.price,
                settleAmount: this.priceDataItem.rebatePrice,
                commisionMoney: this.priceDataItem.commisionMoney == null ? 0 : this.priceDataItem.commisionMoney,
                airportTax: this.priceDataItem.airportTax,
                billingFee: this.priceDataItem.billingFee,
                fuelTax: this.priceDataItem.fuelTax,
                fullTax: this.priceDataItem.fuelTax,
                uuid:this.uuid,
                policyUuid:this.policysList[this.form.policys].policyUuid
            };
            return buyer_dom_white_pnrAmountDetail(body);
        },
        onChangePolicy(val) {
            console.log('111112222233323', 'val -->', val, 'index--->', this.form.policys)
            this.containerLoading = true;
            this.getPnrAmountDetail(val)
                .then((data) => {
                    this.form.sequenceKey=data.sequenceKey;
                    this.pnrAmountDetail = data;
                    this.pnrAmountDetail.orderResponse.result.preferentialExplain = JSON.parse(this.pnrAmountDetail.orderResponse.result.preferentialExplain);
                }, error => {
                    console.log('22222')
                    this.containerLoading = false;
                })
                .finally(() => {
                    console.log('1232ddededede')
                    this.containerLoading = false;
                });
        },
        showReformPopover(item) {
            let ruleBody = {
                flightType: 1,
                flightNo: this.flightInfo.flightNo,
                cabinCode: this.flightInfo.cabinCode,
                depCityPortCode: this.flightInfo.depCityCode,
                arrCityPortCode: this.flightInfo.arrCityCode,
                depDate: this.flightInfo.depDate,
                extData: item.extData,
                policyId: item.policyId,
                psgType: this.psgType,
                supplierType: item.supplierType,
                businessExt: item.businessExt
            };

            Promise.all([
                buyer_dom_white_rule(ruleBody),
                buyer_dom_white_luggage(ruleBody),
            ]).then(data => {
                let refRule = data[0].refRule.rules;
                let chaRule = data[0].chaRule.rules;
                if (refRule && chaRule) {
                    // refRule.map(item => (item.type = 'refRule'));
                    // chaRule.map(item => (item.type = 'chaRule'));
                    this.ruleText = [refRule, chaRule];
                }

                this.luggage = data[1].luggage || '';
            });
        },
        onSubmit() {
            if (!this.checked) {
                this.$message({
                    type: "warning",
                    message: '请先阅读并同意《胤之旅操作规范-机票编 V2.0版》',
                    offset: 80,
                })
                return;
            }
            let validResult = false;
            this.$refs.form.validate(valid => {
                validResult = valid;
            });
            if (!validResult) {
                this.$alert('请填写必填信息', '提示', {
                    confirmButtonText: '确定',
                });
                return;
            };
            let rawData = JSON.parse(JSON.stringify(this.rawData));
            rawData.policys = [this.policysList[this.form.policys]];
            rawData.policys[0].priceData[0] = Object.assign({}, rawData.policys[0].priceData[0], {
                payFee: 0,
                billingFee: 0,
                settlement: rawData.policys[0].priceData[0].rebatePrice,
            })
            let flights = [{
                rawData,
                policys: rawData.policys,
                flightNo: this.flightInfo.flightNo,
                depDate: this.flightInfo.depDate,
                depTime: this.flightInfo.depTime,
                arrDate: this.flightInfo.arrDate,
                arrTime: this.flightInfo.arrTime,
                depAirportCode: this.flightInfo.depAirportCode,
                arrAirportCode: this.flightInfo.arrAirportCode,
                segmentType: 1,
            }];

            // let billingFee = rawData.policys[0].priceData[0].billingFee;
            let billingFee = 0; // 测试数据
            let showBillingFee = rawData.policys[0].priceData[0].showBillingFee;

            let reserveDetail = {
                sequenceKey: '',
                flightType: 1,
                psgType: this.psgType,
                flights,
                billingFee,
                showBillingFee,
                adultNumber: '', // 取不到值，暂时传空
                childNumber: '', // 取不到值，暂时传空
                supplierType: rawData.policys[0].supplierType,
            };

            let passengers = this.form.passengerList.map(item => {
                return Object.assign({}, item, {
                    idCard: item.cardNo,
                    idType: item.cardType,
                    isCheck: 0, // 取不到值，暂时传 0
                    phone: item.mobile,
                    name: item.psgNameCn,
                    userType: item.psgType,
                    gender: item.sex,
                    countryCode: item.countryCode,
                    issCountryCode: item.issCountryCode,
                    cardExpireDate: item.cardExpireDate,
                });
            });
            let buyerRequest = {
                contactQQ: '', // 取不到值，暂时传空
                contactEmail: this.form.contactEmail,
                contactName: this.form.contactName,
                contactPhone: this.form.contactPhone,
                remark: this.form.remark,
                isOpenUp: this.pnrAmountDetail.orderResponse.result.isOpenUp,
                fullDecrement: this.pnrAmountDetail.orderResponse.result.fullDecrement || 0,
                preferentialExplain: JSON.stringify(this.pnrAmountDetail.orderResponse.result.preferentialExplain),
                sequenceKey: '',
                needPayAmount: this.pnrAmountDetail.needPayAmount, // 未计算
                fullReductionId: this.pnrAmountDetail.orderResponse.result.fullReductionId || '0',
                orderSourceType: 5,
                passengers,
            };

            let body = {
                reserveDetail,
                buyerRequest,
                importPnr: this.flightInfo.importPnr,
                sequenceKey:this.form.sequenceKey,
            };
            debugger;
            console.log("submit  sequenceKey --->>>", this.form.sequenceKey);
            const loading = this.$loading({
                lock: true,
                text: '正在创建订单...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            buyer_dom_white_pnrOrder(body).then(res => {
                const orderNo = res.orderNo;
                this.$router.push({
                    name: "air-pay",
                    query: {
                        ordersNo: orderNo,
                    }
                });
            }).finally(() => {
                loading.close();
            });
        },
        validatePhone(rule, value, callback) {
            if (value === "") {
                callback(new Error("请输入手机号"));
            } else {
                if (!/^1[3456789]\d{9}$/.test(value)) {
                    callback(new Error("请输入正确的手机号"));
                } else {
                    callback();
                }
            }
        },
        scrollHandler() {
            if (this.loading === true) return;
            const { bottom } = this.$refs.detailTips.getBoundingClientRect();
            this.fixedSubmit = bottom + 95 > document.documentElement.clientHeight;
        },
        // 获取成人的 priceData
        adultPriceData(priceData) {
            return priceData.filter(item => item.crewType === '1')[0];
        },
        // 格式化数字
        numberToFixedTwo(num) {
            const str = String(num);
            if (!Number(str)) {
                return '0.00';
            } else {
                return Number(num).toFixed(2);
            }
        },
        showBlank(item, last) {
            let num = 0
            if (item.teenOldInfo && item.teenOldInfo.ageScopTag) {
                num++
            }
            if (item.teenOldInfo && item.teenOldInfo.pasgScopTag) {
                num++
            }
            if (item.teenOldInfo && item.teenOldInfo.cardTypeScopTag) {
                num++
            }
            return num > last
        },
        changeNationlity(item, index, val) {
            item.countryCode = val.code
        },
        changeIssNationlity(item, index, val) {
            item.issCountryCode = val.code
        },
        cardExpireDateDisabledDate(val) {
            const now = moment(val).format('YYYY-MM-DD')
            const nowTime = moment(now + ' 00:00:00').valueOf()
            const today = moment().format('YYYY-MM-DD')
            const todayTime = moment(today + ' 00:00:00').valueOf();
            return nowTime < todayTime
        },
    },
    computed: {
        priceDataItem() {
          if (this.policysList.length != 0) {
            return this.policysList[this.form.policys].priceData.filter(item => item.crewType === '1')[0];
          }
        },
    },
    created() {
        this.init();
    },
    beforeDestroy() {
        document.removeEventListener('scroll', this.scrollHandler);
    },
    components: {
        ChangClause,
        airStop,
        LuggageClause,
        NationalitySelector
    },
}
