const __request = require(`../__request/__request_contentType_json`);

// http://183.58.24.99:9999/doc.html#/yinzhilv-flight/PNR%E5%AF%BC%E5%85%A5/orderAmountDetailUsingPOST
export default (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/buyer/dom/white/pnrAmountDetail',
    data: data
  }

  return __request(pParameter)
}

